



























































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";

export default Vue.extend({
    props: {
        users: {
            type: Array,
        },
        notificationInfo: {
            type: Object
        }
    },
    data() {
        return {
            util,
            userManager,
            id: null,
            item: {},
            messages: [],
            userInfo: {},
            currentMessage: "",
            currentImages: [],
            currentFile: "",
            currentReplyToMessage: null,
            infoShown: false,
            urlFunc(item) {
                return Vue.filter("discussionMessageImageUrl")(item);
            },
        };
    },
    computed: {},
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            this.item = await apiClient.getByIdNotification(this.id);
            if (Array.isArray(this.item)) {
                this.item = this.item[0]
            }
            await this.refreshMessages(this.id);
        },
        async refreshMessages(notification_id) {
            const params = {
                notification_id: notification_id
            }
            const items = await apiClient.notificationMessageGetAll(params)
            this.messages = items;
            console.log(this.notificationInfo)
        },
        async sendCurrentMessage() {
            if (!this.currentMessage) {
                return;
            }
            const params = {
                notification_id: this.id,
                content: this.currentMessage
            }
            const res = await apiClient.notificationCreateComment(params)
            if (res) {
                await this.refreshMessages(this.id);
                this.currentMessage = ""
            }

        },
        messageHtml(message) {
            return message.replace(/\n/g, "<br/>");
        },
        getName(value, data, keyData = "id", keyName = "name") {
            if (value) {
                if (Array.isArray(data)) {
                    const length = data.length
                    for (let i = 0; i < length; i++) {
                        if (String(data[i][keyData]) === String(value)) {
                            return data[i][keyName]
                        }
                    }
                }
                return value
            }
            return ""
        },
        isKd(message) {
            const obj = this.users.find(item => String(item.id) === String(message))
            return obj && obj.roles.indexOf("kd") !== -1;
        },
        isGd(message) {
            const obj = this.users.find(item => String(item.id) === String(message))
            return obj && obj.roles.indexOf("gd") !== -1;
        },
    },
    created() {
        this.initialize();
        this.userInfo = userManager.getUserInfo()
    },
});
