































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import Comment from "@/views/app/notification/Comment.vue";
import VueHtml2pdf from "vue-html2pdf";

export default Vue.extend({
  components: {
    Comment,
    VueHtml2pdf
  },
  data() {
    return {
      userManager,
      item: {},
      customer: {},
      users: [],
      print: false,
      name: ""
    };
  },
  computed: {
    availableRoles() {
      const items = [];
      for (const k in this.availableRoleMap) {
        items.push({
          text: this.availableRoleMap[k],
          value: k
        });
      }
      return items;
    }
  },
  watch: {
    $route() {
      this.getByIdNotification();
    }
  },
  created() {
    this.initialize();
    this.getAllUser();
  },
  methods: {
    async initialize() {
      await this.getByIdNotification();
    },
    getByIdNotification() {
      const id = this.$route.params.id;
      apiClient.getByIdNotification(id).then(res => {
        this.item = res;
      });
      const userId = userManager.getUserInfo().sub;
      const params = {
        user_id: userId,
        notification_id: id
      };
      this.changeStatusNotification(params);
    },
    getName(value, data, keyData = "id", keyName = "name") {
      if (value) {
        if (Array.isArray(data)) {
          const length = data.length;
          for (let i = 0; i < length; i++) {
            if (data[i][keyData] === value) {
              return data[i][keyName];
            }
          }
        }
        return value;
      }
      return "";
    },
    async getAllUser() {
      apiClient
        .userGetAll()
        .then(res => {
          this.users = res.items.map(item => {
            item.id = String(item.id);
            return item;
          });
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {});
    },
    generateReport() {
      this.$nextTick(() => {
        this.$refs.html2Pdf.generatePdf();
      });
    },
    async changeStatusNotification() {
      // const res = await apiClient.changeStatusNotification(data)
    }
  }
});
